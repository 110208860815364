import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import TextHero from "../components/textHero"
import Description from "../components/description"
import Gallery from "../components/gallery"
import styled from "styled-components"
import Footer from "../components/footer"
import Focus from "../components/focus"

const AboutPage = ({ data: { page } }) => {
  // console.log(page)
  return (
    <Layout>
      <SEO title={page.seo.title} />
      <Header theme="dark" />
      <Wrapper>
        <TextHero
          title={page.title}
        />
        <Description text={page.text} />
        {page.gallery.length > 0 && <Gallery gallery={page.gallery} />}
        <Focus
          title={page.focusTitle}
          text={page.focusText}
          image={page.focusImage}
        />
        <Footer />
      </Wrapper>
    </Layout>
  )
}

export default AboutPage

const Wrapper = styled.div`
  & > * {
    margin-bottom: 6rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const query = graphql`
  query HomePageQuery {
    page: datoCmsAboutPage {
      title
      text
      focusTitle
      focusText
      focusImage {
        fluid(maxWidth: 700, imgixParams: { fm: "webp" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      gallery {
        fixed(width: 730, imgixParams: { fm: "webp" }) {
          ...GatsbyDatoCmsFixed
        }
        originalId
      }
      seo {
        title
      }
    }
  }
`
